/*
 * @Author: dingguowei
 * @Date: 2023-05-18 11:26:03
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-05-29 16:12:28
 */
import request from '@/plugins/axios'
const base = '/'

export function getXhmDetail(params) {
  return request({
    url: base + 'api/jd/xhmDetail/lg/get',
    method: 'get',
    params
  })
}

export function getXhmDaily(params) {
  return request({
    url: base + 'api/jd/xhmDaily/lg/get',
    method: 'get',
    params
  })
}

export function getXhmOrder(params) {
  return request({
    url: base + 'api/jd/xhmOrder/lg/get',
    method: 'get',
    params
  })
}
export function getMxXhmOrder(params) {
  return request({
    url: base + 'api/jd/xhmOrder/mx/get',
    method: 'get',
    params
  })
}

export function downloadXhmOrder(params) {
  return request({
    url: base + 'api/jd/xhmOrder/lg/download',
    method: 'get',
    params
  })
}

export function downloadMxXhmOrder(params) {
  return request({
    url: base + 'api/jd/xhmOrder/mx/download',
    method: 'get',
    params
  })
}

export function downloadAllXhmOrder(params) {
  return request({
    url: base + 'api/jd/xhmOrder/lg/download/all',
    method: 'get',
    params
  })
}

export function downloadAllMxXhmOrder(params) {
  return request({
    url: base + 'api/jd/xhmOrder/mx/download/all',
    method: 'get',
    params
  })
}

export function getMxXhmDetail(params) {
  return request({
    url: base + 'api/jd/xhmDetail/mx/get',
    method: 'get',
    params
  })
}

export function getMxXhmDaily(params) {
  return request({
    url: base + 'api/jd/xhmDaily/mx/get',
    method: 'get',
    params
  })
}